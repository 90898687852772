import React, { useState, useEffect } from 'react';
import CustomSelect from './CustomSelect';
import { TextField } from '@material-ui/core';
import Button from 'components/common/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { cryptoUtils } from 'parcel-sdk';

import {
  makeSelectOrganisationType,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import {
  makeSelectTokensDetails,
  makeSelectLoading,
  makeSelectTokensDropdown,
  makeSelectTokenList,
} from 'store/tokens/selectors';
import addTeamReducer from 'store/add-team/reducer';
import addTeamSaga from 'store/add-team/saga';
import { addTeam } from 'store/add-team/actions';
import {
  makeSelectLoading as makeSelectAddingTeam,
  makeSelectError as makeSelectErrorInAdd,
} from 'store/add-team/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import {
  makeSelectUpdating,
  makeSelectError as makeSelectErrorInUpdate,
} from 'store/modify-team/selectors';
import modifyTeamReducer from 'store/modify-team/reducer';
import modifyTeamSaga from 'store/modify-team/saga';
import { editTeam } from 'store/modify-team/actions';
import { useActiveWeb3React, useLocalStorage } from 'hooks';
import { getPeopleByTeam } from 'store/view-people/actions';
import { makeSelectPeopleByTeam } from 'store/view-people/selectors';
import { getDecryptedDetails } from 'utils/encryption';
import { FormFieldsWrapper } from './styles';
import defaultTokenIcon from 'assets/icons/tokens/Default-icon.jpg';

const addTeamKey = 'addTeam';
const modifyTeamKey = 'modifyTeam';

function AddTeam({
  tokensToRender,
  setDataToEdit,
  dataToEdit,
  data,
  setEditOpen,
  editOpen,
  isEditMode,
  departmentId,
  setAddOpen,
  addOpen,
}) {
  const [tokens, setTokens] = useState([]);
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');
  const { account } = useActiveWeb3React();
  // Reducers
  useInjectReducer({ key: addTeamKey, reducer: addTeamReducer });
  useInjectReducer({ key: modifyTeamKey, reducer: modifyTeamReducer });

  // Sagas
  useInjectSaga({ key: addTeamKey, saga: addTeamSaga });
  useInjectSaga({ key: modifyTeamKey, saga: modifyTeamSaga });

  const dispatch = useDispatch();

  const safeAddress = useSelector(makeSelectOwnerSafeAddress());
  const tokenDetails = useSelector(makeSelectTokensDetails());
  const organisationType = useSelector(makeSelectOrganisationType());
  const peopleByTeam = useSelector(makeSelectPeopleByTeam());
  const adding = useSelector(makeSelectAddingTeam());
  const updating = useSelector(makeSelectUpdating());
  const errorInAdd = useSelector(makeSelectErrorInAdd());
  const errorInUpdate = useSelector(makeSelectErrorInUpdate());
  const loadingTokenList = useSelector(makeSelectLoading());
  const tokensDropdown = useSelector(makeSelectTokensDropdown());
  const tokenList = useSelector(makeSelectTokenList());

  useEffect(() => {
    if (tokenList && tokenList.length) {
      const USD = {
        decimals: 0,
        logoURI:
          'https://images-parcel.fra1.digitaloceanspaces.com/usdIcon.png',
        name: 'United States Dollar',
        symbol: 'USD',
      };

      const tokens = [];
      tokenList.forEach(token => {
        tokens.push({
          name: token.name,
          decimals: token.decimals,
          logoURI: token.icon,
          symbol: token.name,
          address: token.address,
        });
      });
      setTokens([...tokens, USD]);
    }
  }, [tokenList]);
  const validationSchema = yup.object({
    name: yup.string().required('Name required'),
    currency: yup.string().required('required'),
  });

  useEffect(() => {
    if (isEditMode && safeAddress && departmentId) {
      dispatch(getPeopleByTeam(safeAddress, departmentId));
    }
  }, [isEditMode, dispatch, safeAddress, departmentId]);

  const onSubmit = values => {
    try {
      const tokenInfo = tokenDetails && tokenDetails[values.currency];

      if (account && safeAddress && tokenInfo) {
        const body = {
          name: values.name,
          safeAddress,
          createdBy: account,
          tokenInfo,
        };

        if (isEditMode) {
          const peopleDetails = peopleByTeam.map(
            ({ data, departmentName, ...rest }) => {
              const { firstName, lastName, salaryAmount, address } =
                getDecryptedDetails(data, encryptionKey, organisationType);
              const encryptedData = cryptoUtils.encryptDataUsingEncryptionKey(
                JSON.stringify({
                  firstName,
                  lastName,
                  salaryAmount,
                  salaryToken: tokenInfo.symbol,
                  address,
                }),
                encryptionKey,
                organisationType
              );

              return {
                data: encryptedData,
                departmentName: values.name,
                ...rest,
              };
            }
          );

          dispatch(editTeam({ ...body, departmentId, peopleDetails }));
        } else {
          dispatch(addTeam(body));
        }
      }
      if (isEditMode) {
        setEditOpen(false);
      } else {
        setAddOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: data ? data.name : '',
      currency: data ? data.tokenInfo.symbol : '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmit(values);
    },
  });

  const getTokenImg = tokenSymbol => {
    let curTOken = tokens.filter(({ symbol }) => symbol === tokenSymbol)[0];
    return curTOken?.logoURI || '';
  };

  return (
    <div>
      <div className="customDropDownEdit">
        {isEditMode ? <div className="title">Edit team</div> : <></>}
        <form onSubmit={formik.handleSubmit}>
          <FormFieldsWrapper className="select-wrapper custom-mui-select">
            <TextField
              // placeholder="Full Name"
              variant="filled"
              fullWidth
              label="Name"
              defaultValue={data ? data.name : ''}
              style={{
                marginTop: '10px',
              }}
              FormHelperTextProps={{
                style: {
                  fontSize: 12,
                },
              }}
              InputProps={{
                style: {
                  fontSize: 14,
                  border: '1px solid rgba(235,236,239,0.8)',
                  background: '#FFFFFF',
                  fontFamily: 'Avenir Pro',
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 14,
                  fontFamily: 'Avenir Pro',
                },
              }}
              id="name"
              name="name"
              onClick={e => e.stopPropagation()}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <div className="d-flex select-token-wrapper">
              <CustomSelect
                value={formik.values.currency}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.currency && Boolean(formik.errors.currency)
                // }
                //helperText={formik.touched.currency && formik.errors.currency}
                options={tokens}
                formik={formik}
                setDataToEdit={setDataToEdit}
                dataToEdit={dataToEdit}
                name="currency"
                id="currency"
                clearable={true}
                placeholder={'Currency'}
                renderOptions="tokens"
                defaultValue={data?.tokenInfo?.symbol}
              />
              {formik.values.currency !== '' && formik.values.currency ? (
                formik.values.currency === 'USD' ? (
                  <span
                    className="usd-token"
                    style={{
                      fontSize: '18px',
                      height: '2.2rem',
                      width: '2.4rem',
                      lineHeight: '2rem',
                    }}
                  >
                    $
                  </span>
                ) : (
                  <img
                    src={
                      tokens.find(el => el.symbol === formik.values.currency)
                        ?.logoURI || defaultTokenIcon
                    }
                    alt="tokenLogo"
                    style={{ height: '25px', width: '25px' }}
                  ></img>
                )
              ) : null}
            </div>
            {formik.touched.currency && Boolean(formik.errors.currency) && (
              <div style={{ color: 'red', fontSize: 12 }} className="my-2 ml-3">
                {formik.errors.currency}
              </div>
            )}
          </FormFieldsWrapper>
          {isEditMode && (
            <div className="title-muted">
              PS: Currency of every person in the team will be changed to this
              currency
            </div>
          )}
          <div className="action-buttons">
            <Button
              type="button"
              style={{
                background: 'rgba(115, 103, 240, 0.1)',
                color: '#7367F0',
                fontWeight: 600,
                width: '100%',
              }}
              onClick={e => {
                e.stopPropagation();
                isEditMode ? setEditOpen(false) : setAddOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={e => e.stopPropagation()}
              loading={adding || updating}
              style={{
                background: '#7367F0',
                color: '#FFFFFF',
                marginLeft: '10px',
                fontWeight: 600,
                width: '100%',
              }}
            >
              {isEditMode ? 'Confirm' : 'Add'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddTeam;
