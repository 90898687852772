import { metaTxResubmitEndpoint } from 'constants/endpoints';

export const getFinalMetaTransactionHash = async (metaTxHash, networkId) => {
  const resp = await fetch(
    `${metaTxResubmitEndpoint}transactionHash=${metaTxHash}&networkId=${networkId}`
  );
  const result = await resp.json();
  if (result.data.newStatus === 'CONFIRMED') {
    return result.data.newHash;
  }
};

export const getFinalMetaTransactionStatus = async (metaTxHash, networkId) => {
  const resp = await fetch(
    `${metaTxResubmitEndpoint}transactionHash=${metaTxHash}&networkId=${networkId}`
  );
  const result = await resp.json();
  if (result.data.newStatus === 'CONFIRMED') {
    return { hash: result.data.newHash, status: 'CONFIRMED' };
  } else if (result.data.newStatus === 'FAILED') {
    return { hash: null, status: 'FAILED' };
  } else {
    return {};
  }
};
