import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { cryptoUtils } from 'parcel-sdk';

import ParcelLoader from 'components/common/ParcelLoader';
import Button from 'components/common/Button';
import {
  ErrorMessage,
  CurrencyInput,
  SelectToken,
  Input,
} from 'components/common/Form';
import {
  useMassPayout,
  useLocalStorage,
  useActiveWeb3React,
  useContract,
} from 'hooks';
import transactionsReducer from 'store/transactions/reducer';
import transactionsSaga from 'store/transactions/saga';
import {
  clearTransactionHash,
  addTransaction,
} from 'store/transactions/actions';
import {
  makeSelectError as makeSelectErrorInCreateTx,
  makeSelectLoading as makeSelectSingleOwnerAddTxLoading,
  makeSelectMetaTransactionHash,
} from 'store/transactions/selectors';
import metaTxReducer from 'store/metatx/reducer';
import metaTxSaga from 'store/metatx/saga';
import { getMetaTxEnabled } from 'store/metatx/actions';
import { makeSelectIsMetaTxEnabled as makeSelectIsMetaTxLimitAllowed } from 'store/metatx/selectors';
import { makeSelectSelectedAddress } from 'store/transactions/selectors';
import safeReducer from 'store/safe/reducer';
import safeSaga from 'store/safe/saga';
import { getNonce } from 'store/safe/actions';
import {
  makeSelectNonce,
  makeSelectLoading as makeSelectLoadingSafeDetails,
} from 'store/safe/selectors';
import { createMultisigTransaction } from 'store/multisig/actions';
import { makeSelectUpdating as makeSelectAddTxLoading } from 'store/multisig/selectors';
import multisigSaga from 'store/multisig/saga';
import multisigReducer from 'store/multisig/reducer';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import tokensReducer from 'store/tokens/reducer';
import tokensSaga from 'store/tokens/saga';
import {
  makeSelectOwnerSafeAddress,
  makeSelectIsMultiOwner,
  makeSelectOrganisationType,
  makeSelectIsMetaTxEnabled,
} from 'store/global/selectors';
import { getTokens } from 'store/tokens/actions';
import {
  makeSelectLoading as makeSelectLoadingTokens,
  makeSelectTokenList,
  makeSelectPrices,
} from 'store/tokens/selectors';
import { TRANSACTION_MODES } from 'constants/transactions';
import { formatNumber } from 'utils/number-helpers';
import { constructLabel } from 'utils/tokens';
import ErrorText from 'components/common/ErrorText';
import { networkId } from 'constants/networks';
import { getEtherscanLink, minifyAddress } from 'components/common/Web3Utils';
import { isPolygonMainnet, resetTimes } from 'constants/index';
import {
  makeSelectPeople,
  makeSelectLoading as makeSelectLoadingPeople,
} from 'store/view-people/selectors';
import { getAllPeople } from 'store/view-people/actions';
import viewPeopleSaga from 'store/view-people/saga';
import viewPeopleReducer from 'store/view-people/reducer';
import { QuickTransferContainer } from './styles';
import { getDecryptedDetails } from 'utils/encryption';
import SelectPeople from 'components/common/Form/SelectPeople';
import ScheduleFooter from 'components/ScheduleFooter';
import Radio from 'components/common/Radio';
import PaymentSchedule from 'components/PaymentSchedule';
import GelatoLogo from 'assets/icons/gelato.svg';
import { getAmountFromWei } from 'utils/tx-helpers';
import addresses from 'constants/addresses';
import AutomationModuleABI from 'constants/abis/AutomationModule.json';
import ERC20ABI from 'constants/abis/ERC20.json';
import { ReactComponent as InfoIcon } from 'assets/icons/dashboard/info-icon.svg';
import getTransactionReceipts from 'utils/getTransactionReceipts';
import { getFinalMetaTransactionHash } from 'utils/meta-tx-helpers';

const transactionsKey = 'transactions';
const safeKey = 'safe';
const multisigKey = 'multisig';
const tokensKey = 'tokens';
const metaTxKey = 'metatx';
const viewPeopleKey = 'viewPeople';

const payoutType = ['One Time', 'Recurring'];

const { AUTOMATION_MODULE_ADDRESS, ZERO_ADDRESS } = addresses;

const renderPayoutTypeLabel = val => {
  if (val === 'Recurring') {
    return (
      <div className="flex center gelato-wrapper">
        {val} <span>-</span>
        <img src={GelatoLogo} alt="gelato" />
      </div>
    );
  } else {
    return val;
  }
};

export default function QuickTransfer(props) {
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');

  const { handleHide } = props;
  const { library, account } = useActiveWeb3React();
  const [submittedTx, setSubmittedTx] = useState(false);
  const [selectedTokenDetails, setSelectedTokenDetails] = useState();
  const [existingTokenDetails, setExistingTokenDetails] = useState();
  const [payoutDetails, setPayoutDetails] = useState(null);
  const [tokensDropdown, setTokensDropdown] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [people, setPeople] = useState([]);
  const [allAutomations, setAllAutomations] = useState({});
  const [loadingAutomatedTransactions, setLoadingAutomatedTransactions] =
    useState(false);

  const [metaTxHash, setMetaTxHash] = useState('');

  const automationModule = useContract(
    AUTOMATION_MODULE_ADDRESS,
    AutomationModuleABI
  );
  const erc20Contract = useContract(ZERO_ADDRESS, ERC20ABI);

  const {
    txHash,
    loadingTx,
    massPayout,
    txData,
    createAutomatedPayout,
    loadingMetaTx,
    setLoadingMetaTx,
  } = useMassPayout({
    tokenDetails: selectedTokenDetails,
  });
  // Reducers
  useInjectReducer({ key: tokensKey, reducer: tokensReducer });
  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });
  useInjectReducer({ key: safeKey, reducer: safeReducer });
  useInjectReducer({ key: multisigKey, reducer: multisigReducer });
  useInjectReducer({ key: metaTxKey, reducer: metaTxReducer });
  useInjectReducer({ key: viewPeopleKey, reducer: viewPeopleReducer });
  // Sagas
  useInjectSaga({ key: tokensKey, saga: tokensSaga });
  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });
  useInjectSaga({ key: safeKey, saga: safeSaga });
  useInjectSaga({ key: multisigKey, saga: multisigSaga });
  useInjectSaga({ key: metaTxKey, saga: metaTxSaga });
  useInjectSaga({ key: viewPeopleKey, saga: viewPeopleSaga });

  const {
    register,
    errors,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const selectedToken = watch('token') && watch('token').value;
  const tokenValue = watch('amount');
  const paymentRepeatPattern =
    (watch('repeatPattern') && watch('repeatPattern')) || '';
  // const scheduleEndsOn = watch('scheduleEndsOn') && watch('scheduleEndsOn');
  // const scheduleEndsOnAfter = watch('endsOnInput') && watch('endsOnInput');
  // const firstPaymentDate =
  //   watch('firstPaymentDate') && watch('firstPaymentDate');
  const payeeAddress = watch('address') && watch('address').value;
  const selectedPayoutType = (watch('payoutType') && watch('payoutType')) || '';

  const dispatch = useDispatch();

  // Selectors
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const loadingTokens = useSelector(makeSelectLoadingTokens());
  const tokenList = useSelector(makeSelectTokenList());
  const txHashFromMetaTx = useSelector(makeSelectMetaTransactionHash());
  const errorFromMetaTx = useSelector(makeSelectErrorInCreateTx());
  const addingMultisigTx = useSelector(makeSelectAddTxLoading());
  const addingSingleOwnerTx = useSelector(makeSelectSingleOwnerAddTxLoading());
  const nonce = useSelector(makeSelectNonce());
  const isMultiOwner = useSelector(makeSelectIsMultiOwner());
  const loadingSafeDetails = useSelector(makeSelectLoadingSafeDetails());
  const prices = useSelector(makeSelectPrices());
  const organisationType = useSelector(makeSelectOrganisationType());
  const isMetaEnabled = useSelector(makeSelectIsMetaTxEnabled());
  const isMetaTxLimitAllowed = useSelector(makeSelectIsMetaTxLimitAllowed());
  const encryptedPeople = useSelector(makeSelectPeople());
  const loadingPeople = useSelector(makeSelectLoadingPeople());
  const selectedAddress = useSelector(makeSelectSelectedAddress());

  const getERC20Contract = useCallback(
    contractAddress => {
      if (contractAddress) return erc20Contract.attach(contractAddress);
      return erc20Contract;
    },
    [erc20Contract]
  );

  useEffect(() => {
    const getAllAutomations = async () => {
      if (ownerSafeAddress && automationModule) {
        setLoadingAutomatedTransactions(true);
        try {
          const start = 0;
          const pageSize = 255; // max uint8 value
          const allDelegates = await automationModule.getDelegates(
            ownerSafeAddress,
            start,
            pageSize
          );
          const { results: delegates } = allDelegates;
          const automations = {};

          const delegatesPromises = delegates.map(async delegate => {
            const tokens = await automationModule.getTokens(
              ownerSafeAddress,
              delegate
            );

            if (tokens && tokens.length > 0) {
              const tokensPromises = tokens.map(async token => {
                const tokenAllowance = await automationModule.getTokenAllowance(
                  ownerSafeAddress,
                  delegate,
                  token
                );

                let tokenName;
                let tokenAddress = token;
                let decimals = 18;

                try {
                  const amount = getAmountFromWei(tokenAllowance[0], decimals);
                  if (amount !== 0) {
                    if (
                      tokenAddress !== addresses.ZERO_ADDRESS &&
                      tokenAddress !== addresses.OTHER_ZERO_ADDRESS
                    ) {
                      const erc20 = getERC20Contract(tokenAddress);
                      decimals = await erc20.decimals();
                      tokenName = await erc20.symbol();
                      automations[delegate][tokenName] = {
                        delegate: delegate,
                        allowanceAmount: getAmountFromWei(
                          tokenAllowance[0],
                          decimals
                        ),
                        spentAmount: getAmountFromWei(
                          tokenAllowance[1],
                          decimals
                        ),
                        resetTimeMin: tokenAllowance[2].toNumber(),
                        lastResetMin: tokenAllowance[3].toNumber(),
                        tokenName,
                        tokenAddress,
                      };
                    } else {
                      automations[delegate][tokenName] = {
                        delegate: delegate,
                        allowanceAmount: getAmountFromWei(
                          tokenAllowance[0],
                          decimals
                        ),
                        spentAmount: getAmountFromWei(
                          tokenAllowance[1],
                          decimals
                        ),
                        resetTimeMin: tokenAllowance[2].toNumber(),
                        lastResetMin: tokenAllowance[3].toNumber(),
                        tokenName: tokenName,
                        tokenAddress: addresses.ZERO_ADDRESS,
                      };
                    }
                  }
                } catch (error) {
                  console.error('error with token: ', tokenAddress, error);
                }
              });

              await Promise.all(tokensPromises);
            }
          });

          await Promise.all(delegatesPromises);

          setLoadingAutomatedTransactions(false);
          setAllAutomations(automations);
        } catch (err) {
          console.error(err);
          setLoadingAutomatedTransactions(false);
          return {};
        }
      }
    };

    getAllAutomations();
  }, [automationModule, getERC20Contract, ownerSafeAddress]);

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getTokens(ownerSafeAddress));
      dispatch(getNonce(ownerSafeAddress));
      dispatch(getMetaTxEnabled(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch]);

  useEffect(() => {
    if (
      txHashFromMetaTx &&
      isMetaEnabled &&
      !isMultiOwner &&
      isMetaTxLimitAllowed
    ) {
      setLoadingMetaTx(true);
      dispatch(clearTransactionHash());
      const intervalId = setInterval(async () => {
        const newMetaHash = await getFinalMetaTransactionHash(
          txHashFromMetaTx,
          networkId
        );
        if (newMetaHash) {
          getTransactionReceipts(
            library,
            dispatch,
            ownerSafeAddress,
            '',
            newMetaHash,
            false
          );
          setMetaTxHash(newMetaHash);
          setLoadingMetaTx(false);
          clearInterval(intervalId);
        }
      }, 3000);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    txHashFromMetaTx,
    isMetaEnabled,
    isMultiOwner,
    isMetaTxLimitAllowed,
  ]);

  useEffect(() => {
    if (!encryptedPeople) dispatch(getAllPeople(ownerSafeAddress));
  }, [dispatch, encryptedPeople, ownerSafeAddress]);

  useEffect(() => {
    if (selectedToken && existingTokenDetails) {
      setSelectedTokenDetails(
        existingTokenDetails.filter(({ name }) => name === selectedToken)[0]
      );
    }
  }, [selectedToken, existingTokenDetails]);

  useEffect(() => {
    if (encryptedPeople && encryptionKey) {
      const sortedDecryptedPeople = encryptedPeople
        .map(({ data, ...rest }) => {
          const { firstName, lastName, salaryAmount, salaryToken, address } =
            getDecryptedDetails(data, encryptionKey, organisationType);
          return {
            firstName,
            lastName,
            salaryAmount,
            salaryToken,
            address,
            ...rest,
          };
        })
        .sort((a, b) =>
          a.firstName &&
          b.firstName &&
          a.firstName.toUpperCase() > b.firstName.toUpperCase()
            ? 1
            : -1
        );
      setPeople(sortedDecryptedPeople);
    }
  }, [encryptedPeople, encryptionKey, organisationType, ownerSafeAddress]);

  const totalAmountToPay = useMemo(() => {
    if (payoutDetails && payoutDetails.length > 0) {
      return payoutDetails.reduce((total, { usd }) => (total += usd), 0);
    }

    return 0;
  }, [payoutDetails]);

  useEffect(() => {
    if (txHash) {
      setSubmittedTx(true);
      if (
        encryptionKey &&
        payoutDetails &&
        ownerSafeAddress &&
        totalAmountToPay &&
        selectedTokenDetails
      ) {
        const to = cryptoUtils.encryptDataUsingEncryptionKey(
          JSON.stringify(payoutDetails),
          encryptionKey,
          organisationType
        );
        // const to = selectedTeammates;
        dispatch(
          addTransaction({
            to,
            safeAddress: ownerSafeAddress,
            createdBy: ownerSafeAddress,
            transactionHash: txHash,
            tokenValue: payoutDetails.reduce(
              (total, { salaryAmount }) => (total += parseFloat(salaryAmount)),
              0
            ),
            tokenCurrency: selectedTokenDetails.name,
            fiatValue: totalAmountToPay,
            addresses: payoutDetails.map(({ address }) => address),
            transactionMode:
              selectedPayoutType.toLowerCase() === 'recurring'
                ? TRANSACTION_MODES.AUTOMATION
                : TRANSACTION_MODES.QUICK_TRANSFER, // quick transfer
            nonce: nonce,
            description: payoutDetails?.length && payoutDetails[0]?.description,
            paymentType: 'quick',
          })
        );
      }
    } else if (txData) {
      if (
        encryptionKey &&
        payoutDetails &&
        ownerSafeAddress &&
        totalAmountToPay &&
        selectedTokenDetails
      ) {
        const to = cryptoUtils.encryptDataUsingEncryptionKey(
          JSON.stringify(payoutDetails),
          encryptionKey,
          organisationType
        );

        if (!isMultiOwner) {
          // threshold = 1 or single owner

          dispatch(
            addTransaction({
              to,
              safeAddress: ownerSafeAddress,
              createdBy: account,
              txData,
              tokenValue: payoutDetails.reduce(
                (total, { salaryAmount }) =>
                  (total += parseFloat(salaryAmount)),
                0
              ),
              tokenCurrency: selectedTokenDetails.name,
              fiatValue: totalAmountToPay,
              addresses: payoutDetails.map(({ address }) => address),
              transactionMode:
                selectedPayoutType.toLowerCase() === 'recurring'
                  ? TRANSACTION_MODES.AUTOMATION
                  : TRANSACTION_MODES.QUICK_TRANSFER, // quick transfer
              nonce: nonce,
              description:
                payoutDetails?.length && payoutDetails[0]?.description,
              paymentType: 'quick',
              isMetaEnabled,
            })
          );
        } else {
          // threshold > 1
          dispatch(
            createMultisigTransaction({
              to,
              safeAddress: ownerSafeAddress,
              createdBy: account,
              txData,
              tokenValue: payoutDetails.reduce(
                (total, { salaryAmount }) =>
                  (total += parseFloat(salaryAmount)),
                0
              ),
              tokenCurrency: selectedTokenDetails.name,
              fiatValue: totalAmountToPay,
              fiatCurrency: 'USD',
              addresses: payoutDetails.map(({ address }) => address),
              transactionMode:
                selectedPayoutType.toLowerCase() === 'recurring'
                  ? TRANSACTION_MODES.AUTOMATION
                  : TRANSACTION_MODES.QUICK_TRANSFER, // quick transfer
              nonce: nonce,
              description:
                payoutDetails?.length && payoutDetails[0]?.description,
              paymentType: 'quick',
            })
          );
        }
      }
    }
  }, [
    txHash,
    encryptionKey,
    payoutDetails,
    dispatch,
    ownerSafeAddress,
    totalAmountToPay,
    selectedTokenDetails,
    txData,
    account,
    isMultiOwner,
    nonce,
    organisationType,
    selectedPayoutType,
    isMetaEnabled,
  ]);

  useEffect(() => {
    if (tokenList && tokenList.length > 0 && !tokensDropdown.length) {
      setExistingTokenDetails(tokenList);
      setTokensDropdown(
        tokenList.map(details => ({
          value: details.name,
          label: constructLabel({
            token: details.name,
            component: (
              <div>
                {formatNumber(details.balance)} {details.name}
              </div>
            ),
            imgUrl: details.icon,
          }),
        }))
      );
    }
  }, [tokenList, tokensDropdown]);

  useEffect(() => {
    if (submittedTx || metaTxHash) {
      // handleHide();
      // dispatch(
      //   show(TX_SUBMITTED_MODAL, {
      //     txHash: txHash ? txHash : metaTxHash,
      //     selectedCount: 1,
      //     transactionId: singleOwnerTransactionId,
      //   })
      // );
      setSuccessState(true);
      setTimeout(() => {
        setSuccessState(false);
        handleHide();
      }, 3000);
    }
    // eslint-disable-next-line
  }, [
    // dispatch,
    // metaTxHash,
    // singleOwnerTransactionId,
    // txHash,
    metaTxHash,
    submittedTx,
    handleHide,
  ]);

  const checkIfAutomationExists = (tokenAddress, delegate) => {
    if (delegate in allAutomations) {
      if (
        allAutomations[delegate].tokenAddress.toLowerCase() ===
        tokenAddress.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  };

  const onSubmit = async values => {
    const payoutDetails = [
      {
        address: payeeAddress,
        firstName: values.address.firstName || '',
        lastName: values.address.lastName || '',
        salaryAmount: values.amount,
        salaryToken: selectedTokenDetails.name,
        description: values.description || '',
        usd:
          (selectedTokenDetails.usd / selectedTokenDetails.balance) *
          values.amount,
      },
    ];
    setPayoutDetails(payoutDetails);

    try {
      if (values.payoutType === 'Recurring') {
        await createAutomatedPayout(
          payoutDetails,
          selectedTokenDetails.name,
          resetTimes[values.repeatPattern],
          values.gasPrice,
          nonce,
          isMultiOwner,
          isMetaEnabled,
          isMetaTxLimitAllowed
        );
      } else {
        await massPayout(
          payoutDetails,
          selectedTokenDetails.name,
          isMultiOwner,
          nonce,
          isMetaEnabled,
          isMetaTxLimitAllowed
        );
      }
    } catch (error) {
      console.error(error);
      setErrorState(true);
      setTimeout(() => setErrorState(false), [3000]);
    }
  };

  const renderQuickTransfer = () => {
    const btnLoading =
      loadingMetaTx ||
      loadingTx ||
      addingMultisigTx ||
      addingSingleOwnerTx ||
      loadingAutomatedTransactions;
    const btnDisabled =
      !formState.isValid ||
      loadingTx ||
      loadingMetaTx ||
      addingMultisigTx ||
      addingSingleOwnerTx ||
      loadingSafeDetails ||
      loadingAutomatedTransactions;

    return (
      <div>
        <div className="title">Payout Type</div>
        <div className="flex center">
          {payoutType.map((label, i) => (
            <Radio
              register={register}
              key={i}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: i !== 0 && '5rem',
              }}
              id={`${label}-${i}`}
              name="payoutType"
              label={() => renderPayoutTypeLabel(label)}
              value={label}
              defaultChecked={label === 'One Time'}
            />
          ))}
        </div>
        <div className="mt-5 title">Paying To</div>
        <div className="recipient-container">
          <div className="mb-3">
            <SelectPeople
              name="address"
              control={control}
              required={`Wallet Address is required`}
              options={people}
              isSearchable
              isClearable
              placeholder="Wallet Address"
              isLoading={loadingPeople}
              register={register}
              setValue={setValue}
              defaultValue={selectedAddress}
            />
            <ErrorMessage name="address" errors={errors} />
          </div>
          <div>
            <Input
              type="text"
              name="description"
              register={register}
              placeholder="Enter Description"
            />
          </div>
        </div>
        {selectedPayoutType.toLowerCase() === 'recurring' && (
          <div className="mt-5">
            <PaymentSchedule register={register} />
          </div>
        )}

        <div className="title mt-5">Paying From</div>
        <div
          style={{ display: 'flex', alignItems: 'flex-start' }}
          className="mb-3"
        >
          <SelectToken
            name="token"
            control={control}
            required={`Token is required`}
            width="20rem"
            options={tokensDropdown}
            isSearchable
            placeholder={`Select Currency...`}
            defaultValue={null}
            isLoading={loadingTokens}
            className="mt-1 mr-5"
          />

          {selectedToken && (
            <div className="ml-2">
              <Controller
                control={control}
                name="amount"
                rules={{
                  required: 'Amount is required',
                  validate: value => {
                    if (value <= 0) return 'Please check your input';
                    else if (
                      selectedTokenDetails &&
                      parseFloat(value) >
                        parseFloat(selectedTokenDetails.balance)
                    )
                      return 'Insufficient balance';

                    return true;
                  },
                }}
                defaultValue=""
                render={({ onChange, value }) => (
                  <CurrencyInput
                    type="number"
                    name="amount"
                    value={value}
                    onChange={onChange}
                    placeholder="0.00"
                    conversionRate={
                      prices &&
                      selectedTokenDetails &&
                      prices[selectedTokenDetails.name]
                    }
                    tokenName={
                      selectedTokenDetails ? selectedTokenDetails.name : ''
                    }
                  />
                )}
              />
              <ErrorMessage name="amount" errors={errors} />
            </div>
          )}
        </div>

        {selectedPayoutType.toLowerCase() === 'recurring' ? (
          <>
            <div className="divider"></div>

            {selectedTokenDetails &&
              checkIfAutomationExists(
                selectedTokenDetails.address,
                payeeAddress
              ) && (
                <div className="warning-container">
                  <InfoIcon fill="white" className="warning-icon" />
                  <div className="text">
                    There already exists an automation for this address
                    associated with this token. It will be ovewritten.
                  </div>
                </div>
              )}

            <ScheduleFooter
              btnLoading={btnLoading}
              btnDisabled={btnDisabled}
              tokenValue={tokenValue}
              selectedToken={selectedToken}
              paidTo={minifyAddress(payeeAddress)}
              repeatPattern={paymentRepeatPattern}
              safeBalance={
                selectedTokenDetails &&
                formatNumber(selectedTokenDetails.balance) +
                  ' ' +
                  selectedTokenDetails.name
              }
              minFieldsForTransaction={
                selectedToken &&
                selectedTokenDetails &&
                payeeAddress &&
                tokenValue
              }
            />
          </>
        ) : (
          <div className="footer-button-wrapper">
            <Button
              type="submit"
              width="20rem"
              style={{ minWidth: '18rem' }}
              loading={btnLoading}
              disabled={btnDisabled}
            >
              Create Transaction
            </Button>
          </div>
        )}

        {errorFromMetaTx && <ErrorText>{errorFromMetaTx}</ErrorText>}
      </div>
    );
  };

  const redirectToEtherscan = () => {
    if (txHash) {
      const url = getEtherscanLink({
        chainId: networkId,
        hash: txHash,
      });
      window.open(url, '_blank');
    }
    if (metaTxHash) {
      const url = getEtherscanLink({
        chainId: networkId,
        hash: metaTxHash,
      });
      window.open(url, '_blank');
    }
  };

  const redirectToDashboard = () => {
    setSuccessState(false);
    handleHide();
  };

  const goBackToReviewDetails = () => {
    setErrorState(false);
  };

  const showLoader = loadingTx || errorState || successState;

  return (
    <QuickTransferContainer>
      {showLoader && (
        <div className="loader-container">
          <ParcelLoader size={200} error={errorState} success={successState}>
            {successState && (
              <>
                <Button type="button" onClick={redirectToDashboard}>
                  Go back
                </Button>
                <Button type="button" onClick={redirectToEtherscan}>
                  View Transaction on{' '}
                  {isPolygonMainnet ? 'Polygonscan' : 'Etherscan'}
                </Button>
              </>
            )}
            {errorState && (
              <Button type="button" onClick={goBackToReviewDetails}>
                Go back to Review Transaction
              </Button>
            )}
          </ParcelLoader>
        </div>
      )}
      <div className={showLoader ? 'container-blur' : ''}>
        <form onSubmit={handleSubmit(onSubmit)}>{renderQuickTransfer()}</form>
      </div>
    </QuickTransferContainer>
  );
}
