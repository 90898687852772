import React, { useState, useEffect } from 'react';
import Img from 'components/common/Img';
import { Container, Header, Section } from './styles';

import Search from 'assets/icons/search.svg';
import Add from 'assets/icons/add.svg';
import Upload from 'assets/icons/upload.svg';
import TeamFilters from './TeamFilters';
import DetailTable from './DetailTable';
import { Popover as PopOver } from 'reactstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeSelectTokensDetails } from 'store/tokens/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedPeopleGroupByTeams as setSelectedPeopleGroupByTokenToGlobalState,
  setAmountPerToken,
} from 'store/transactions/actions';
import viewTeamsReducer from 'store/view-teams/reducer';
import { getTeams } from 'store/view-teams/actions';
import viewTeamsSaga from 'store/view-teams/saga';
import viewPeopleReducer from 'store/view-people/reducer';
import {
  getAllPeople,
  addPeopleFilter,
  removePeopleFilter,
  setSearchName,
} from 'store/view-people/actions';
import ExportButton from './ExportButton';
import { PEOPLE_FILTERS } from 'store/view-people/constants';
import viewPeopleSaga from 'store/view-people/saga';
import {
  makeSelectPeople,
  makeSelectLoading as makeSelectLoadingPeople,
  makeSelectIsSearchByTeamFilterApplied,
  makeSelectIsSearchByNameFilterApplied,
  makeSelectNameFilter,
  makeSelectTeamFilter,
  makeSelectSearchName,
} from 'store/view-people/selectors';
import { makeSelectLoading as makeSelectLoadingTeam } from 'store/view-teams/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import {
  makeSelectOrganisationType,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import AddPeople from './AddPeople';
import AddTeam from './AddTeam';
import AddBulkPeoplModal from '../People/AddBulkPeopleModal';

import { ReactComponent as Delete } from '../../assets/icons/delete-bin.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search_icon.svg';
import BulkDeletePeopleModal, {
  MODAL_NAME as BULK_DELETE_PEOPLE_MODAL,
} from './BulkDeletePeopleModal';
import { ReactComponent as Send } from 'assets/icons/send.svg';

// import ControlledInput from 'components/common/Input';
// import TeamsDropdown from './TeamsDropdown';
// import AddPeopleDropdown from './AddPeopleDropdown';
// import SearchByTeamDropdown from './SearchByTeamDropdown';
// import ExportButton from './ExportButton';
// import Button from 'components/common/Button';
// import Avatar from 'components/common/Avatar';
// import {
//   Table,
//   TableHead,
//   TableBody,
//   TableTitle,
//   TableInfo,
//   TableLoader,
// } from 'components/common/Table';
// import { InfoCard, TeamContainer } from './styles';
import { useLocalStorage } from 'hooks';
import { getDecryptedDetails } from 'utils/encryption';
import { makeSelectTeams } from 'store/view-teams/selectors';
import { MODAL_NAME as ADD_BULK_MODAL } from '../People/AddBulkPeopleModal';
import { show } from 'redux-modal';
import {
  ActionButton,
  Dot,
  NotificationArea,
  NotifyArea,
} from 'components/Automations/styles';
import { addPeopleToBulkDelete } from 'store/modify-people/actions';
import { TextField } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { MODAL_NAME as MASS_PAYOUT_ACROSS_TEAMS_MODAL } from 'components/Payments/MassPayoutAcrossTeamsModal';
// import AddBulkPeoplModal from './AddBulkPeopleModal';
// import AddSinglePeopleModal, {
//   MODAL_NAME as ADD_SINGLE_MODAL,
// } from './AddSinglePeopleModal';
// import TokenImg from 'components/common/TokenImg';
// import DeleteTeamModal from './DeleteTeamModal';
// import AddTeamModal from './AddTeamModal';
// import ViewTeamsModal from './ViewTeamsModal';
// import { show } from 'redux-modal';

const viewTeamsKey = 'viewTeams';
const viewPeopleKey = 'viewPeople';

export default function PeoplesNew() {
  const [addOpen, setAddOpen] = useState(false);
  const [tokensToRender, setTokensToRender] = useState([]);
  const tokenDetails = useSelector(makeSelectTokensDetails());
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');
  const [value, setValue] = React.useState(0);

  const [isNewUser, setIsNewUser] = useState();
  const [allPeople, setAllPeople] = useState([]);
  const [tempAllPeople, setTempAllPeople] = useState([]);
  const [sortedDecrypted, setSortDecrypted] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectAllPeople, setSelectAllPeople] = useState(false);
  const [addNewPeople, setAddNewPeople] = useState({});
  const [searchText, setSearchText] = useState('');
  const [peopleByTeam, setPeopleByTeam] = useState();
  const [filteredPeople, setFilteredPeople] = useState();
  const [teamNameToIdMap, setTeamNameToIdMap] = useState();
  const [peopleByAlphabet, setPeopleByAlphabet] = useState();
  const [showSearch, setShowSearch] = useState(false);

  useInjectReducer({
    key: viewTeamsKey,
    reducer: viewTeamsReducer,
  });
  useInjectReducer({ key: viewPeopleKey, reducer: viewPeopleReducer });

  useInjectSaga({ key: viewTeamsKey, saga: viewTeamsSaga });
  useInjectSaga({ key: viewPeopleKey, saga: viewPeopleSaga });

  const dispatch = useDispatch();
  const allTeams = useSelector(makeSelectTeams());
  const loadingPeople = useSelector(makeSelectLoadingPeople());
  const loadingTeams = useSelector(makeSelectLoadingTeam());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const encryptedPeople = useSelector(makeSelectPeople());
  const organisationType = useSelector(makeSelectOrganisationType());
  const isNameFilterApplied = useSelector(
    makeSelectIsSearchByNameFilterApplied()
  );
  const isTeamFilterApplied = useSelector(
    makeSelectIsSearchByTeamFilterApplied()
  );
  const nameFilter = useSelector(makeSelectNameFilter());
  const teamFilter = useSelector(makeSelectTeamFilter());
  const searchPeopleValue = useSelector(makeSelectSearchName());

  useEffect(() => {
    if (!allTeams) dispatch(getTeams(ownerSafeAddress));
    if (!encryptedPeople) dispatch(getAllPeople(ownerSafeAddress));
  }, [dispatch, ownerSafeAddress, encryptedPeople, allTeams]);

  useEffect(() => {
    if (
      (encryptedPeople && encryptedPeople.length > 0) ||
      (allTeams && allTeams.length > 0)
    ) {
      setIsNewUser(false);
    } else {
      setIsNewUser(true);
    }
  }, [encryptedPeople, allTeams]);

  useEffect(() => {
    if (!searchPeopleValue && isNameFilterApplied) {
      dispatch(removePeopleFilter(PEOPLE_FILTERS.NAME));
    }

    if (searchPeopleValue) {
      dispatch(
        addPeopleFilter(PEOPLE_FILTERS.NAME, searchPeopleValue.toLowerCase())
      );
    }
  }, [dispatch, searchPeopleValue, isNameFilterApplied]);

  useEffect(() => {
    if (allPeople && nameFilter) {
      const filteredPeople = allPeople.filter(({ firstName, lastName }) =>
        `${firstName} ${lastName}`.toLowerCase().includes(nameFilter)
      );

      setFilteredPeople(filteredPeople);
    }
  }, [allPeople, nameFilter]);
  useEffect(() => {
    if (allTeams && allTeams.length > 0) {
      const teamNameToIdMap = allTeams.reduce((map, { departmentId, name }) => {
        map[name] = departmentId;
        return map;
      }, {});
      setTeamNameToIdMap(teamNameToIdMap);
    }
  }, [allTeams]);

  useEffect(() => {
    if (selectedPeople && selectedPeople.length > 0) {
      let allPeopleIds = allPeople.map(p => p.peopleId);
      let newSelectedPeople = selectedPeople.filter(people => {
        return allPeopleIds.includes(people.peopleId);
      });
      if (selectedPeople.length !== newSelectedPeople.length) {
        setSelectedPeople(newSelectedPeople);
      }
    }
  }, [allPeople, selectedPeople]);

  useEffect(() => {
    if (allPeople && allPeople.length > 0 && selectedTeams.length > 0) {
      setSelectedPeople(allPeople);
    } else {
      setSelectedPeople([]);
    }
  }, [allPeople, selectedTeams]);

  useEffect(() => {
    if (encryptedPeople && encryptionKey) {
      const sortedDecryptedPeople = encryptedPeople
        .map(({ data, ...rest }) => {
          const { firstName, lastName, salaryAmount, salaryToken, address } =
            getDecryptedDetails(data, encryptionKey, organisationType);
          return {
            firstName,
            lastName,
            salaryAmount,
            salaryToken,
            address,
            ...rest,
          };
        })
        .sort((a, b) =>
          a.firstName &&
          b.firstName &&
          a.firstName.toUpperCase() > b.firstName.toUpperCase()
            ? 1
            : -1
        );
      setSortDecrypted(sortedDecryptedPeople);
      setAllPeople(sortedDecryptedPeople);
      setTempAllPeople(sortedDecrypted);
      const peopleByAlphabet = sortedDecryptedPeople.reduce(
        (accumulator, people) => {
          const alphabet = people.firstName[0].toUpperCase();
          if (!accumulator[alphabet]) {
            accumulator[alphabet] = [people];
          } else {
            accumulator[alphabet].push(people);
          }

          return accumulator;
        },
        {}
      );

      setPeopleByAlphabet(peopleByAlphabet);

      const peopleByTeam = allTeams
        ? allTeams.reduce((accumulator, { name }) => {
            accumulator[name] = [];
            return accumulator;
          }, {})
        : {};

      for (let i = 0; i < sortedDecryptedPeople.length; i++) {
        const people = sortedDecryptedPeople[i];
        if (peopleByTeam[people.departmentName]) {
          peopleByTeam[people.departmentName].push(people);
        }
      }

      setPeopleByTeam(peopleByTeam);
    }
  }, [encryptedPeople, encryptionKey, organisationType, allTeams]);

  useEffect(() => {
    setTokensToRender(tokenDetails);
  }, [tokenDetails, value]);

  useEffect(() => {
    let filteredPeople = [];
    if (searchText.length < 1 && selectedTeams.length < 1) {
      setAllPeople(sortedDecrypted);
      setAllPeople(sortedDecrypted);
      setTempAllPeople(sortedDecrypted);
      return;
    }
    if (searchText.length < 1 && selectedTeams.length > 0) {
      setAllPeople(tempAllPeople);
      return;
    }
    if (allPeople.length !== sortedDecrypted) {
      let filteredPeople = filterSearch(tempAllPeople, searchText);

      setAllPeople(filteredPeople);
      return;
    }

    filteredPeople = filterSearch(sortedDecrypted, searchText);

    setAllPeople(filteredPeople);
  }, [searchText, sortedDecrypted]);

  const handleSelectPeople = people => {
    if (!selectedPeople.some(el => el.peopleId === people.peopleId)) {
      setSelectedPeople([...selectedPeople, people]);
    } else {
      const updatedSelect = selectedPeople.filter(
        el => el.peopleId !== people.peopleId
      );

      setSelectedPeople(updatedSelect);
    }
  };

  const handleSelectAllPeople = () => {
    if (!selectAllPeople) {
      setSelectedPeople(allPeople);
      setSelectAllPeople(true);
    } else {
      setSelectedPeople([]);
      setSelectAllPeople(false);
    }
  };
  useEffect(() => {
    if (
      selectedPeople.length > 0 &&
      allPeople.length > 0 &&
      selectedPeople.length === allPeople.length
    ) {
      setSelectAllPeople(true);
    } else {
      setSelectAllPeople(false);
    }
  }, [selectedPeople]);

  const filterSearch = (array, searchText) => {
    const filteredPeople = array.filter(({ firstName, lastName }) =>
      `${firstName} ${lastName}`
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    return filteredPeople;
  };

  const showBulkAddModal = () => {
    dispatch(show(ADD_BULK_MODAL));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showBulkDeletePeopleModal = () => {
    dispatch(addPeopleToBulkDelete(selectedPeople));
    dispatch(show(BULK_DELETE_PEOPLE_MODAL));
  };

  const initiatePayout = () => {
    const groupingByTeams = {};

    selectedPeople.forEach(data => (groupingByTeams[data.departmentName] = []));

    selectedPeople.forEach(data => {
      groupingByTeams[data.departmentName] = [
        ...groupingByTeams[data.departmentName],
        data,
      ];
    });

    const groupingByToken = {};
    let salaryAmountByToken = {};

    selectedPeople.forEach(data => (groupingByToken[data.salaryToken] = []));

    selectedPeople.forEach(data => {
      groupingByToken[data.salaryToken] = [
        ...groupingByToken[data.salaryToken],
        data,
      ];
    });
    Object.entries(groupingByToken).forEach(([key, value], idx) => {
      salaryAmountByToken[key] = value.reduce(
        (acc, { salaryAmount }) => acc + Number(salaryAmount),
        0
      );
    });

    dispatch(setSelectedPeopleGroupByTokenToGlobalState(groupingByTeams));
    dispatch(setAmountPerToken(salaryAmountByToken));

    dispatch(show(MASS_PAYOUT_ACROSS_TEAMS_MODAL));
  };
  return (
    <Container>
      <Header>
        <div className="title">People</div>
        <div className="actions">
          {/* {!showSearch && <div className="items" onClick={()=>setShowSearch(!showSearch)}>
           <Img src={Search} alt="search" />
            <div className="text">Search</div> 
           
          </div>}
          {showSearch &&<div className="items" onMouseOut={()=>setShowSearch(!showSearch)} >
       
           
          </div>} */}
          <div className="items">
            <TextField
              // className="search-input"
              variant="filled"
              margin="dense"
              className="search-text-box"
              placeholder="Search People"
              type="text"
              onChange={e => setSearchText(e.target.value)}
              InputProps={{
                style: {
                  fontSize: 14,
                  width: 239,
                },
                startAdornment: <SearchIcon />,
              }}
            />
          </div>

          <div>
            <div className="items" id="PopoverAdd">
              <Img src={Add} alt="add" />
              <div className="text" style={{ marginTop: 0 }}>
                Add
              </div>
            </div>
            <PopOver
              placement="bottom"
              isOpen={addOpen}
              target="PopoverAdd"
              trigger="legacy"
              toggle={() => setAddOpen(!addOpen)}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                className="custom-tab"
                centered
              >
                <Tab
                  label="Add People"
                  style={{ textTransform: 'capitalize' }}
                />
                <Tab label="Add Team" style={{ textTransform: 'capitalize' }} />
              </Tabs>

              <AddPeople
                tokensToRender={tokensToRender}
                setTokensToRender={setTokensToRender}
                allTeams={allTeams}
                setAddOpen={setAddOpen}
                tokenDetails={tokenDetails}
                addOpen={addOpen}
                index={0}
                value={value}
                loading={loadingTeams}
                disabled={loadingTeams}
              />
              <div role="tabpanel" hidden={value !== 1}>
                {value === 1 && (
                  <div>
                    <AddTeam
                      tokensToRender={tokensToRender}
                      setAddOpen={setAddOpen}
                      addOpen={addOpen}
                      isEditMode={false}
                      loading={loadingTeams}
                      disabled={loadingTeams}
                    />
                  </div>
                )}
              </div>
            </PopOver>
          </div>

          <div className="items" onClick={showBulkAddModal}>
            <Img src={Upload} alt="upload" />
            <div className="text" style={{ marginTop: 0 }}>
              Upload
            </div>
          </div>

          <ExportButton />
        </div>
      </Header>

      {selectedPeople.length ? (
        <NotificationArea>
          <NotifyArea>
            <Dot></Dot>
            {`Selected ${selectedPeople.length} out of ${
              allPeople.length
            } people ${
              selectedTeams.length > 0 &&
              selectedTeams.length !== allTeams.length
                ? `from ${selectedTeams.length} teams `
                : ''
            }`}
          </NotifyArea>
          <div className="d-flex align-items-center">
            <ActionButton
              style={{ color: '#FF4B55' }}
              onClick={() => showBulkDeletePeopleModal()}
            >
              <Delete
                style={{
                  marginRight: '8px',
                  height: '12px',
                }}
              />
              Delete
            </ActionButton>
            <ActionButton
              className="initiate-payout-btn"
              onClick={initiatePayout}
            >
              <Send width={16} className="icons" />
              Initiate Payout
            </ActionButton>
          </div>
        </NotificationArea>
      ) : null}

      <Section>
        <TeamFilters
          allTeams={allTeams}
          allPeople={allPeople}
          setAllPeople={setAllPeople}
          setTempAllPeople={setTempAllPeople}
          sortedDecrypted={sortedDecrypted}
          loadingPeople={loadingPeople}
          loadingTeams={loadingTeams}
          searchText={searchText}
          filterSearch={filterSearch}
          setSelectedTeams={setSelectedTeams}
          selectedTeams={selectedTeams}
        />
        <DetailTable
          allPeople={allPeople}
          selectedPeople={selectedPeople}
          handleSelectOne={handleSelectPeople}
          handleSelectAllPeople={handleSelectAllPeople}
          selectAllPeople={selectAllPeople}
          loadingPeople={loadingPeople}
          loadingTeams={loadingTeams}
        />
      </Section>
      <AddBulkPeoplModal />
      <BulkDeletePeopleModal setSelectedPeople={setSelectedPeople} />
    </Container>
  );
}
