import { Link } from 'react-router-dom';
import { fetchMultisigTransactionWithDelay } from 'store/multisig/actions';
import { showToast, toaster } from 'components/common/Toast';
import SuccessLogo from 'assets/icons/success.svg';
import ErrorLogo from 'assets/icons/error.svg';

const renderToast = (redirectURI = '', transactionSuccessful) => {
  return (
    <div className="d-flex align-items-center">
      <div>
        <img
          src={transactionSuccessful ? SuccessLogo : ErrorLogo}
          alt="transaction-status"
          className="toast-transaction-status"
        />
      </div>
      <div className="toast-body-container">
        <p className="toast-title">
          Transaction {transactionSuccessful ? 'Completed' : 'Failed'}
        </p>
        <Link to={`/dashboard/transactions/${redirectURI}`}>View details</Link>
      </div>
    </div>
  );
};

const getTransactionReceipts = (
  library,
  dispatch,
  safeAddress,
  transactionId,
  transactionHash,
  isMultiOwner
) => {
  const intervalId = setInterval(async () => {
    localStorage.removeItem('currentMetaTxHash');
    const receipt = await library.getTransactionReceipt(transactionHash);

    if (receipt) {
      const isSuccessful = receipt?.status === 1 ? true : false;

      if (isSuccessful) {
        toaster.dismiss();
        showToast(renderToast(transactionId, true), 'success', {
          toastId: `${transactionId}-txConfirmed`,
        });
      } else {
        toaster.dismiss();
        showToast(renderToast(transactionId, false), 'error', {
          toastId: `${transactionId}-txConfirmed`,
        });
      }

      if (isMultiOwner) {
        dispatch(fetchMultisigTransactionWithDelay(safeAddress, transactionId));
      }

      clearInterval(intervalId);
    }
  }, 3000);
};

export default getTransactionReceipts;
